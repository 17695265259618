@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightRegular);
  vertical-align: middle;
  gap: 10px;
  flex-shrink: 0;

  & b {
    font-weight: var(--fontWeightSemiBold);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* Establece el número máximo de líneas a mostrar */
    overflow: hidden;
  }
}

.iconStar {
  width: 14px;
  height: 14px;
  fill: var(--colorAttention);
}
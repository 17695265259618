@import '../../../styles/customMediaQueries.css';

.root {}

.content {
  display: flex;
  flex-direction: row;
}

.author {
  display: flex;
  flex-direction: column;
  gap: 18px;

}

.authorRating {
  display: flex;
  height: min-content;
  gap: 12px;
  margin-right: 36px;
  border: var(--colorGrey100) 1px solid;
  border-radius: 100px;
  padding: 4px 18px 4px 4px;
  background-color: var(--colorWhite);
  transition: var(--transitionStyle);
  pointer-events: none;

  &:hover {
    box-shadow: var(--boxShadowPopup);
  }
}

.avatar {
  flex-shrink: 0;
  width: 45px;
  height: 45px;

  pointer-events: auto
}

.info {
  flex: 1;
}

.link {
  white-space: nowrap;
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 12px 0 0 0;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */

  }
}

.links {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.authorName {
  composes: p from global;
  margin: 0;
  padding: 0;
  font-weight: var(--fontWeightBold);
}

.editProfile {
  composes: buttonSecondaryInline from global;
  width: min-content;
  margin: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-grow: 0;
}

.contact {
  composes: buttonAttention from global;

  cursor: pointer;

  &:hover {}
}